import gql from 'graphql-tag';

export default gql`
  query subscriber($id: ID, $email: String) {
    subscriber(id: $id, email: $email) {
      id
      email
      pfmSub
      jcWtSub
      jcUpSub
      newsSub
      rsrchSub
    }
  }
`;
