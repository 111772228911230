import { action, thunk } from 'easy-peasy';

import TEXT_ZJC from '../graphql/textZjcLink';

const modalDisplayModel = {
  modalOpen: null,
  showZjc: false,

  setShowZjc: action((state, payload) => {
    state.showZjc = payload;
  }),

  openModal: action((state, payload) => {
    state.modalOpen = payload ? payload : 'get-started';
  }),

  closeModal: action((state, payload) => {
    state.modalOpen = null;
  }),

  sendZjcText: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: TEXT_ZJC,
      variables: { input: { ...payload.variables } },
    });

    if (response?.data?.textZjcLink.errors.length === 0) {
      payload.callback && payload.callback();
    } else {
      payload.onError && payload.onError(response.data.textZjcLink.errors);
    }
  }),
};

export default modalDisplayModel;
