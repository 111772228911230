import { createStore } from 'easy-peasy';

import { client } from './Apollo';

import modalDisplayModel from '../models/modalDisplayModel';
import emailSettingsModel from '../models/emailSettingsModel';

const storeModel = {
  modalDisplay: modalDisplayModel,
  emailSettings: emailSettingsModel,
};

export default createStore(storeModel, { injections: { client } });
