import gql from 'graphql-tag';

export default gql`
  mutation textZjcLink($input: TextZjcLinkInput!) {
    textZjcLink(input: $input) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
