import gql from 'graphql-tag';

export default gql`
  mutation upsertSubscriber($input: UpsertSubscriberInput!) {
    upsertSubscriber(input: $input) {
      subscriber {
        id
        email
        pfmSub
        jcWtSub
        jcUpSub
        newsSub
        rsrchSub
      }

      errors {
        path
        message
      }
    }
  }
`;
