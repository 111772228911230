import { action, thunk } from 'easy-peasy';

import GET_SUBSCRIBER from '../graphql/getSubscriber';
import UPSERT_SUBSCRIBER from '../graphql/upsertSubscriber';

const emailSettingsModel = {
  subscriber: {},

  setSubscriber: action((state, payload) => {
    state.subscriber = payload;
  }),
  getSubscriber: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.query({
      query: GET_SUBSCRIBER,
      variables: payload.variables || {},
    });

    action.setSubscriber(response.data.subscriber || {});
    payload.callback && payload.callback(response.data.subscriber);
  }),

  upsertSubscriber: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: UPSERT_SUBSCRIBER,
      variables: { input: { ...payload.variables } },
    });

    if (response?.data?.upsertSubscriber.errors.length === 0) {
      action.setSubscriber(response.data.upsertSubscriber.subscriber || {});
      payload.callback && payload.callback();
    } else {
      payload.onError && payload.onError(response.data.upsertSubscriber.errors);
    }
  }),
};

export default emailSettingsModel;
