export const PFM_API_HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://preprod.askzeta.com'
    : process.env.GATSBY_SITE_ENV && process.env.GATSBY_SITE_ENV === 'preprod'
    ? 'https://preprod.askzeta.com'
    : 'https://www.askzeta.com';
export const PFM_PLATFORM_API = `${PFM_API_HOST}/graphql`;

export const HIVE_HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://preprod.askzeta.com'
    : process.env.GATSBY_SITE_ENV && process.env.GATSBY_SITE_ENV === 'preprod'
    ? 'https://hive-preprod.askzeta.com'
    : 'https://hive.askzeta.com';
export const HIVE_API = `${HIVE_HOST}/graphql`;
