import React from 'react';
import { StoreProvider } from 'easy-peasy';
import store from './src/lib/EasyPeasy';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './src/lib/Apollo';
import { CookiesProvider } from 'react-cookie';

const wrapWithProvider = ({ element }) => (
  <ApolloProvider client={client}>
    <StoreProvider store={store}>
      <CookiesProvider>{element} </CookiesProvider>
    </StoreProvider>
  </ApolloProvider>
);

export default wrapWithProvider;
